$icon-font-path: '../../fonts/';

@import "node_modules/bootstrap-datetime-picker/css/bootstrap-datetimepicker";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/select2/dist/css/select2";

body {
  padding-top: 150px;
  padding-bottom: 200px;
}

.tab-pane {
  padding: 20px;
}

.internal .hide-on-internal {
  display: none;
}

.external .hide-on-external {
  display: none;
}

.hide-on-external form {
  float: left !important;
}

ul.pagination {
  margin: 0;
}

.table-view table {
  td {
    font-size: 0.83em;
    width: 10%;
    line-height: 1.6em !important;
  }
}

.table-view-interactions table {
  td:nth-child(1) {
    width: 1%;
  }

  td:nth-child(2) {
    width: 15%;
  }

  td:nth-child(3) {
    width: 10%;
  }

  td:nth-child(4) {
    width: 3%;
  }

  td:nth-child(5) {
    width: 20%;
  }

  td:nth-child(6) {
    width: 30%;
  }

  td:last-child {
    width: 10%;
  }
}

.table-view-account table {
  td:nth-child(1) {
    width: 1%;
  }

  td:nth-child(5) {
    width: 40%;
  }

  td:last-child {
    width: 5%;
  }
}

.has-followups {
  background: #eee;
}

.datetimepicker table tr td.followup-warning {
  background: #F3BE5A;
}

.datetimepicker table tr td.followup-warning:hover {
  background: #f39c12;
}

.datetimepicker table tr td.followup-danger {
  background: #EA8A7E;
}

.datetimepicker table tr td.followup-danger:hover {
  background: #e74c3c;
}